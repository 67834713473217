import React from "react";

const Egg = ({ className }) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 297 297"
      space="preserve"
      className={className}
    >
      <g>
        <path
          d="M43.513,206.1c2.084,14.552,7.118,28.15,14.483,40.169l13.024-13.024c4.397-4.392,11.518-4.39,15.91,0l15.219,15.221
		l15.22-15.221c4.397-4.392,11.518-4.39,15.91,0l15.219,15.219l15.219-15.219c4.395-4.392,11.516-4.392,15.911,0l15.22,15.221
		l15.219-15.221c4.394-4.392,11.516-4.392,15.91,0l13.024,13.024c7.365-12.019,12.398-25.617,14.483-40.169H43.513z"
        />
        <path d="M43.83,159.75c-0.705,7.796-1.149,15.755-1.317,23.85h211.974c-0.168-8.095-0.612-16.054-1.317-23.85H43.83z" />
        <path
          d="M218.025,257.11l-15.219,15.22c-4.395,4.392-11.516,4.392-15.91,0l-15.221-15.22l-15.219,15.22
		c-2.197,2.196-5.076,3.295-7.955,3.295c-2.88,0-5.758-1.099-7.955-3.295l-15.219-15.22l-15.22,15.22
		c-4.395,4.393-11.516,4.391-15.91,0l-15.219-15.22l-7.131,7.131C91.155,284.427,118.359,297,148.5,297
		c30.141,0,57.345-12.573,76.656-32.759L218.025,257.11z"
        />
        <path
          d="M78.975,86.241L94.195,71.02c4.394-4.392,11.516-4.392,15.91,0l15.22,15.221l15.219-15.221
		c4.395-4.392,11.516-4.392,15.91,0l15.219,15.221l15.221-15.221c4.397-4.392,11.518-4.391,15.91,0l15.219,15.221l13.778-13.778
		C212.377,28.225,182.284,0,148.5,0S84.622,28.225,65.198,72.463L78.975,86.241z M189.277,27.056
		c1.473-1.462,3.498-2.306,5.569-2.306c2.07,0,4.106,0.844,5.568,2.306c1.474,1.462,2.307,3.499,2.307,5.569
		s-0.833,4.106-2.307,5.569c-1.462,1.462-3.498,2.306-5.568,2.306c-2.071,0-4.096-0.844-5.569-2.306
		c-1.462-1.463-2.306-3.499-2.306-5.569S187.815,28.518,189.277,27.056z M142.927,27.056c1.473-1.462,3.499-2.306,5.569-2.306
		c2.07,0,4.106,0.844,5.569,2.306c1.473,1.473,2.306,3.499,2.306,5.569s-0.833,4.095-2.306,5.569
		c-1.463,1.462-3.499,2.306-5.569,2.306c-2.07,0-4.096-0.844-5.569-2.306c-1.462-1.463-2.306-3.499-2.306-5.569
		S141.464,28.518,142.927,27.056z M96.576,27.056c1.474-1.462,3.499-2.306,5.569-2.306c2.07,0,4.107,0.844,5.569,2.306
		c1.473,1.473,2.306,3.499,2.306,5.569s-0.833,4.095-2.306,5.569c-1.462,1.462-3.499,2.306-5.569,2.306
		c-2.07,0-4.095-0.844-5.569-2.306c-1.462-1.463-2.306-3.499-2.306-5.569S95.114,28.518,96.576,27.056z"
        />
        <path
          d="M250.352,137.25c-2.374-14.715-5.695-28.684-9.845-41.671l-14.526,14.526c-2.197,2.196-5.077,3.295-7.955,3.295
		c-2.88,0-5.758-1.099-7.955-3.295L194.85,94.884l-15.22,15.221c-4.395,4.393-11.516,4.392-15.911,0L148.5,94.886l-15.219,15.219
		c-4.395,4.392-11.516,4.392-15.91,0l-15.22-15.221L86.93,110.105c-4.395,4.392-11.516,4.392-15.91,0L56.494,95.579
		c-4.151,12.987-7.472,26.956-9.845,41.671H250.352z"
        />
      </g>
    </svg>
  );
};

export default Egg;
