import React from "react";
import PropTypes from "prop-types";

const Container = ({ children }) => {
    return (
        <>
            <div className="border">
                <div className="border--inner"></div>
            </div>
            <main>
                {children}
            </main>
        </>
    );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
