import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Egg from '../components/egg'

const Navbar = ({ page }) => {
  const hideOverflow = (e) => {
    const main = document.querySelector('main')
    const href = e.target.getAttribute('href').replace('/', '')
    // Only disable overflow if navigating to different page
    if (page && href !== page) {
      main.style.overflow = 'hidden'
    }
  }

  const navListenClick = () => {
    const navItems = document.querySelectorAll('.nav-item')
    navItems.forEach((e) => {
      e.addEventListener('click', function () {
        removeHighlight(e, navItems)
      })
    })
  }
  const removeHighlight = (e, navItems) => {
    const href = e.firstChild.getAttribute('href')
    // Only trigger if the link clicked is a different page and not home link
    if (href) {
      if (page && page !== href.replace('/', '')) {
        navItems.forEach((navItem) => {
          const isSelected = navItem.classList.contains('selected')
          if (isSelected) {
            navItem.classList.remove('selected')
          }
        })
      }
    }
  }

  const setNavColour = () => {
    const navProfileLink = document.querySelector('.go-profile')
    const navWorksLink = document.querySelector('.go-works')
    if (page && page === 'profile') {
      navProfileLink.classList.toggle('selected')
    }
    if (page && page === 'works') {
      navWorksLink.classList.toggle('selected')
    }
  }

  useEffect(() => {
    navListenClick()
    setNavColour()
  }, [])

  return (
    <nav>
      <Link className="logo nav-item" to="/" cursor-class="open-inverted">
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          width="400"
          height="400"
          viewBox="0 0 400 400"
          className={'logo-svg ' + (page && page === 'surprise' ? 'hide' : '')}
        >
          <path
            d="M296.58,73.44l-1.37,42q-39.06-2.93-73.63-2.93-.41,
          36.92-.39,82.23,0,70.32,2.14,133H168.65q2.53-62.12,2.54-133,
          0-44.53-.79-82.23-27.53,0-75.58,3.13l1.56-42.19Z"
          />
          <path
            d="M287.62,306.28A22.61,22.61,0,0,1,281.46,
          322q-6.16,6.76-17,6.77-9.17,0-14.28-5.86a20.57,20.57,
          0,0,1-5.13-14,21.76,21.76,0,0,1,6.16-15.44q6.16-6.52,
          17.15-6.53,9.29,0,14.29,5.68A20.06,20.06,0,0,1,287.62,306.28Z"
          />
        </svg>
        <Egg className={page && page === 'surprise' ? 'show' : 'hide'} />
      </Link>
      <div className="nav-item go-profile">
        <Link
          className="nav-link"
          to="/profile"
          onClick={hideOverflow}
          cursor-class="open-inverted"
        >
          Profile
        </Link>
      </div>
      <div className="nav-item go-works">
        <Link className="nav-link" to="/works" onClick={hideOverflow} cursor-class="open-inverted">
          Works
        </Link>
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  page: PropTypes.string,
}

export default Navbar
