import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

import Navbar from "./navbar";
import Container from "./container";
import ColourToggles from "./toggles";
import Cursor from "./cursor";

// Global CSS styles
import "/static/css/style.css";

const duration = 1;
const delay = 0.5;

const variants = {
  initial: {
    width: "100vw",
    height: "calc(100% - 25px)",
  },
  enter: {
    width: 0,
    height: "20px",
    transition: {
      ease: "easeOut",
      duration: duration,
      delay: duration + 0.475,
      height: {
        duration: duration,
        delay: delay,
      },
    },
  },
  exit: {
    width: "100vw",
    height: "calc(100% - 25px)",
    transition: {
      ease: "easeOut",
      duration: duration,
      delay: delay,
      height: {
        duration: duration,
        delay: duration + 0.35,
      },
    },
  },
};

const Layout = ({ children, page }) => {
  return (
    <>
      <Cursor />
      <ColourToggles />
      <Navbar page={page} />
      {/* <WinXP/> */}
      <motion.div
        className="framer-anim"
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
      ></motion.div>
      <Container>{children}</Container>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
