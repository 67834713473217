import React, { useEffect } from 'react'
import Cookies from 'universal-cookie'

const ColourToggles = () => {
  // Accent first, background second
  const colours = [
    ['#28cf52', '#06160a'],
    ['#E6E7E8', '#050505'],
    ['#FEE715FF', '#101820FF'],
    ['#EC4D37', '#1D1B1B'],
    ['#2a2927', '#e7dfd6'],
  ]

  const getColour = () => {
    const cookies = new Cookies()
    const colour = cookies.get('colour')
    if (!!colour) {
      setColour(colour)
    } else {
      return 2
    }
    return colour
  }

  const setColour = (colour) => {
    const cookies = new Cookies()
    cookies.set('colour', colour, { path: '/' })
    document.documentElement.style.setProperty('--main-color', colours[colour][0])
    document.documentElement.style.setProperty('--background-color', colours[colour][1])
  }

  const changeColour = (e) => {
    const selectedColour = e.target.dataset.colour
    // const allButtons = document.querySelectorAll('.colour-btn')

    // Remove the selected class from all other buttons
    // allButtons.forEach((e) => e.classList.remove("selected"));

    // Select this button as current
    // e.target.classList.add("selected");

    // Set the root colours as the selected colour set
    setColour(selectedColour)
  }

  const setSelected = (colour) => {
    const allButtons = document.querySelectorAll('.colour-btn')

    // allButtons.forEach((e, index) => {
    //   if (index === parseInt(colour)) {
    //     e.classList.add("selected");
    //   } else {
    //     e.classList.remove("selected");
    //   }
    // });
  }

  // Set the colour according to stored cookie
  useEffect(() => {
    const colour = getColour()
    setSelected(colour)
    setColour(colour)
  }, [])

  return (
    <div className="colour-toggle">
      <button className="colour-btn" data-colour={0} onClick={changeColour}>
        <span></span>
        <span></span>
      </button>
      <button className="colour-btn" data-colour={1} onClick={changeColour}>
        <span></span>
        <span></span>
      </button>
      <button className="colour-btn" data-colour={2} onClick={changeColour}>
        <span></span>
        <span></span>
      </button>
      <button className="colour-btn" data-colour={3} onClick={changeColour}>
        <span></span>
        <span></span>
      </button>
      <button className="colour-btn" data-colour={4} onClick={changeColour}>
        <span></span>
        <span></span>
      </button>
    </div>
  )
}

export default ColourToggles
